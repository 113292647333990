<template>
  <div>
    <div class="raffle-page-user-tickets" v-if="wallet_tickets_count">
      <span>Your tickets:</span><span>{{ wallet_tickets_count }}</span>
    </div>
    <div class="raffle-page-background">
      <div class="raffle-page-card">

        <span class="raffle-page-title">{{ raffle.name }}</span>
        <div class="raffle-page-line"></div>
        <div class="raffle-page-nav">
          <a class="raffle-page-link" :href="raffle.discord" v-if="raffle.discord !== null && raffle.discord != ''">
            <img src="/assets/img/discord.png"/>
          </a>
          <a class="raffle-page-link" :href="raffle.website" v-if="raffle.website !== null && raffle.website != ''">
            <img src="/assets/img/web.png"/>
          </a>
          <a class="raffle-page-link" :href="raffle.twitter" v-if="raffle.twitter !== null && raffle.twitter != ''">
            <img src="/assets/img/twitter.png"/>
          </a>
        </div>

        <div class="raffle-page-time desktop" v-if="raffle.time_left != 'closed'">
          <span>
            Ends in:
          </span>
          <span>
            {{ raffle.days }}d 
            {{ raffle.hours }}h 
            {{ raffle.minutes }}m 
            {{ raffle.seconds }}s 
          </span>
        </div>

        <div class="raffle-page-time desktop" v-else>
          <span>
            Raffle Closed
          </span>
        </div>

        <div class="raffle-page-info">

          <div class="raffle-page-nft">
            <img :src="raffle.image" />
          </div>

          <div class="raffle-page-time mobile" v-if="raffle.time_left != 'closed'">
          <span>
            Ends in:
          </span>
            <span>
            {{ raffle.days }}d 
            {{ raffle.hours }}h 
            {{ raffle.minutes }}m 
            {{ raffle.seconds }}s 
          </span>
          </div>

          <div class="raffle-page-time mobile" v-else>
          <span>
            Raffle Closed
          </span>
          </div>

          <div :class="{'raffle-page-details' : true, 'raffle-page-closed' : raffle.time_left == 'closed'}">
            <div class="raffle-page-details-text">
              <p>
                <span>
                  Tickets Sold:
                </span>
                <span v-if="raffle.max_tickets > 0">
                  {{ raffle.tickets_sold }} / {{ raffle.max_tickets }}
                </span>
                <span v-else>
                  {{ raffle.tickets_sold }}
                </span>
              </p>
              <p>
                <span>
                  Winners:
                </span>
                <span>
                  {{ raffle.winners }}
                </span>
              </p>
              <p>
                <span>
                  Price:
                </span>
                <span>
                  {{ raffle.ticket_price }} $AQUA /  Ticket
                </span>
              </p>
              <p class="raffle-page-tickets" v-if="raffle.time_left != 'closed'">
                <span class="raffle-page-gradient-font gradient-font" @click="withdraw_one_ticket()">
                  &#x2212;
                </span>
                <span>
                  {{ how_many_tickets }}
                </span>
                <span class="raffle-page-gradient-font gradient-font" @click="add_one_ticket()">
                  &#x2b;
                </span>
              </p>
            </div>

            <div class="raffle-page-button button" v-if="raffle.time_left != 'closed'">
              <div class="button-left-border"></div>
              <div class="button-right-border"></div>
              <div class="button-content" @click="buy_tickets()">
                <span>Buy</span>
                <span>{{ how_many_tickets }}</span>
                <span>tickets</span>
              </div>
            </div>

          </div>


        </div>

      </div>
    </div>

    <div v-if="Object.entries(raffle.raffle_winners).length > 0" class="winners-container">

      <span class="winners-title">List of winners</span>
      <div class="winners-line"></div>
      <div class="winners-list">
        <div class="winners-list-head">
          <div>Wallet</div>
          <div>Entries</div>
           <div>Claim</div>
        </div>
        <div v-for="(winner, key) in raffle.raffle_winners" :key="key" class="winners-list-element">
          <div>
            <div>
              {{ winner.wallet }}
            </div>
            <div>{{ winner.entries }}</div>
            <div>
			
              <div class="winners-list-claim-button" @click="claimer_id = winner.id; display_modal_claim = true" v-if="winner.wallet == $root.wallet_address && raffle.raffle_type == 'wl'">
                <div>
                  Claim
                </div>
              </div>
				<div v-else>
				-
				</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="cct-modal-background" v-show="display_modal_claim === true">
      <div class="cct-modal-border">
        <div class="cct-modal">
          <div class="cct-modal-close" @click="display_modal_claim = false">X</div>
          <div class="cct-modal-title">Claim info</div>
          <div class="cct-modal-content">
            <div class="cct-modal-icon">
              <img src="/assets/img/twitter.png">
            </div>
            <div class="cct-modal-input">
              <input type="text" v-model="claimer.twitter_name" placeholder="Twitter handle"/>
            </div>
          </div>
          <div class="cct-modal-content">
            <div class="cct-modal-icon">
              <img src="/assets/img/discord.png">
            </div>
            <div class="cct-modal-input">
              <input type="text" v-model="claimer.discord_name" placeholder="Discord handle"/>
            </div>
          </div>

          <div class="cct-modal-button button" style="margin-top: 25px;" @click="claim()">
            <div class="button-left-border"></div>
            <div class="button-right-border"></div>
            <div class="button-content cct-wallet-button-content">
              <span>Claim</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {buy_ticket_program, prepare_buy_ticket_program} from '@/libs/rustProgram';
import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {raffle_timer_time_left_for_raffle} from '@/libs/raffleTimer';
import {cct_alert} from '@/libs/CCT_alert';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

export default {
  name: 'Raffle',
  data : function (){

    return {

		id: this.$route.params.id,
		winners : {},
		raffle: {
			
			raffle_winners: {},
		},
		how_many_tickets: 1,
		claimer_id: false,
    wallet_tickets_count: 0,
    display_modal_claim: false,
    claimer : {
      twitter_name : "",
      discord_name : ""
    }

    }

  },
  methods: {
  
	claim: function() {
	
		var id_winner = this.claimer_id;
		
		var data = {
			
			discord: this.claimer.discord_name,
			twitter: this.claimer.twitter_name,
		};
		
		this.display_modal_claim = false;
		
		axios.post('https://marketplace.bluediamonds.app/cct/edit_winner/'+id_winner, data).then(function (result) {
			
			cct_alert("Thank you, we will come back to you as soon as possible.");
			
		});
	},
	
	withdraw_one_ticket: function() {
		
		this.how_many_tickets--;
		
		if(this.how_many_tickets <= 0)
			this.how_many_tickets = 1;
	},
	
	add_one_ticket: function() {
	
		if(parseInt(this.raffle.max_tickets) > 0 && parseInt(this.raffle.max_tickets) <= parseInt(this.raffle.tickets_sold) + parseInt(this.how_many_tickets))
			return;
		
		this.how_many_tickets++;
	},
	
	buy_tickets: async function() {
	
		if(parseInt(this.raffle.max_tickets) > 0 && parseInt(this.raffle.max_tickets) < parseInt(this.raffle.tickets_sold) + parseInt(this.how_many_tickets)) {
			
			cct_alert("There are not enough tickets available for this purchase");
			return;
		}
		
		if(this.$root.wallet_address === null) {
			
			// alert('Please connect your wallet first');
      this.$root.$emit('display_connection_modal');
			return;
		}
		
		this.$root.loading_gif = true;
		
		await prepare_buy_ticket_program(getWalletAddress(), getSolanaObject(), this.how_many_tickets, this.raffle.id, this.raffle.ticket_price);
		
		this.$root.loading_gif = false;
		
		var component = this;
		
		var id_raffle = component.$route.params.id;
		
		// get live raffle
		axios.get('https://marketplace.bluediamonds.app/cct/get_one_raffle/'+id_raffle).then(function (result) {
			
			component.raffle.tickets_sold = result.data.raffle.tickets_sold;
			
		});

    component.get_wallet_tickets();

  },

  get_wallet_tickets : function (){

    var component = this;

    if(component.$root.wallet_address === null)
      return;

    var id_raffle = component.$route.params.id;

    axios.get('https://marketplace.bluediamonds.app/cct/tickets_bought_for_wallet/' + component.$root.wallet_address).then(function (result) {

      if(result.data[id_raffle])
        component.wallet_tickets_count = result.data[id_raffle];

    });

  }
	
  },
  mounted: function(){
	
		var component = this;
		
		var id_raffle = component.$route.params.id;
		
		// get live raffle
		axios.get('https://marketplace.bluediamonds.app/cct/get_one_raffle/'+id_raffle).then(function (result) {
			
			component.raffle = result.data.raffle;
			
			raffle_timer_time_left_for_raffle(component.raffle);

		});

    component.get_wallet_tickets();

    this.$root.$on('wallet_connexion_success',() => {

      component.get_wallet_tickets();

    });

	},
}
</script>

<style scoped>

</style>